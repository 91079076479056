<template>
<div class="hud">
	<div class="buttons">
		<button class="ctrl up" @click="controller.walkToward(0,-1)" :disabled="blownUp">↑</button>
		<button class="ctrl left" @click="controller.walkToward(-1,0)" :disabled="blownUp">←</button>
		<button class="ctrl right" @click="controller.walkToward(1,0)" :disabled="blownUp">→</button>
		<button class="ctrl down" @click="controller.walkToward(0,1)" :disabled="blownUp">↓</button>
		<button class="ctrl up left" @click="controller.walkToward(-1,-1)" :disabled="blownUp">↖</button>
		<button class="ctrl up right" @click="controller.walkToward(1,-1)" :disabled="blownUp">↗</button>
		<button class="ctrl down left" @click="controller.walkToward(-1,1)" :disabled="blownUp">↙</button>
		<button class="ctrl down right" @click="controller.walkToward(1,1)" :disabled="blownUp">↘</button>
		<span class="ctrl">{{controller.player.symbol}}</span>
	</div>
	<div class="buttons">
		<button class="ctrl up" @click="controller.flagToward(0,-1)" :disabled="blownUp">↑</button>
		<button class="ctrl left" @click="controller.flagToward(-1,0)" :disabled="blownUp">←</button>
		<button class="ctrl right" @click="controller.flagToward(1,0)" :disabled="blownUp">→</button>
		<button class="ctrl down" @click="controller.flagToward(0,1)" :disabled="blownUp">↓</button>
		<button class="ctrl up left" @click="controller.flagToward(-1,-1)" :disabled="blownUp">↖</button>
		<button class="ctrl up right" @click="controller.flagToward(1,-1)" :disabled="blownUp">↗</button>
		<button class="ctrl down left" @click="controller.flagToward(-1,1)" :disabled="blownUp">↙</button>
		<button class="ctrl down right" @click="controller.flagToward(1,1)" :disabled="blownUp">↘</button>
		<span class="ctrl">🏴</span>
	</div>
	<!-- <div>
		<button @click="renderChunks" :disabled="blownUp">Render</button><br>
		<button @click="sendPlayerUpdateToServer" :disabled="blownUp">Save Player</button><br>
		<button @click="requestMapAndPlayers" :disabled="blownUp">Get Map and Players</button>
	</div> -->
	<div class="indicator">
		<h2>Here</h2>
		<h1>{{whatsHere}}</h1>
	</div>
	<div class="indicator">
		<h2>Score</h2>
		<h1>{{controller.player.score}}</h1>
	</div>
	<div class="links">
		<button class="link" @click="controller.showHelp = true">Tutorial</button>
		<button class="link" @click="controller.showScoreboard = true">Scores</button>
		<button class="link" @click="controller.generateDungeon" v-show="showAdminControls">Dungeon</button>
		<button class="link" @click="controller.clearAll" v-show="showAdminControls">Clear</button>
		<router-link class="link" to="/">End Game</router-link>
	</div>
</div>
</template>

<style scoped>
p {
  padding: 0;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}

.hud {
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  background-color: #EEE;
  padding: 10px;
  color: #001;
  flex-wrap: wrap;
}

.buttons {
  position: relative;
  border: 1px solid black;
  width: 150px;
  height: 150px;
  font-size: 30px;
}

.buttons > button {
  font-size: 30px;
}

.ctrl {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%); /* This is a shorthand of*/
}
.left {
  left: calc(50% - 45px);
}
.right {
  left: calc(50% + 45px);
}
.up {
  top: calc(50% - 45px);
}
.down {
  top: calc(50% + 45px);
}

.links {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: baseline;
	justify-content: space-around;
}

.indicator {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 583px) {
	.links {
		flex-direction: row;
		flex-grow: 1;
	}
}

@media only screen and (max-width: 440px) {
	.indicator {
		flex-direction: row;
	}
	.indicator > * {
		margin-left: 15px;
	}

}

@media only screen and (max-width: 319px) {	
	.buttons {
		width: 100px;
		height: 100px;
		font-size: 20px;
	}

	.buttons > button {
		font-size: 20px;
	}

	.ctrl {
		position: absolute;
		width: 30px;
		height: 30px;
		top: 50%;  /* position the top  edge of the element at the middle of the parent */
		left: 50%; /* position the left edge of the element at the middle of the parent */
		transform: translate(-50%, -50%); /* This is a shorthand of*/
	}
	.left {
		left: calc(50% - 30px);
	}
	.right {
		left: calc(50% + 30px);
	}
	.up {
		top: calc(50% - 30px);
	}
	.down {
		top: calc(50% + 30px);
	}
}

</style>


<script>
import { extras, SpaceRef } from '../../public/extras';
export default {
	name: 'HUD',
	props: {
		controller: Object
	},
	data() {
		return {
			whatsHereBackdoor: 0,
		};
	},
	mounted() {
		this.controller.hud = this;

		document.addEventListener('keydown', (ev) => {
			if (ev.repeat || this.controller.showingModal) {
				return;
			}
			
			let direction = { x: 0, y: 0 };
			if (ev.key.toLowerCase() === 'w' || ev.key === "ArrowUp") {
				direction.y = -1;
			} else if (ev.key.toLowerCase() === 's' || ev.key === "ArrowDown") {
				direction.y = 1;
			} else if (ev.key.toLowerCase() === 'a' || ev.key === "ArrowLeft") {
				direction.x = -1;
			} else if (ev.key.toLowerCase() === 'd' || ev.key === "ArrowRight") {
				direction.x = 1;
			} else if (ev.key.toLowerCase() === 'q') {
				direction.x = -1;
				direction.y = -1;
			} else if (ev.key.toLowerCase() === 'e') {
				direction.x = 1;
				direction.y = -1;
			} else if (ev.key.toLowerCase() === 'z') {
				direction.x = -1;
				direction.y = 1;
			} else if (ev.key.toLowerCase() === 'c') {
				direction.x = 1;
				direction.y = 1;
			} else {
				if (ev.key.toLowerCase() === ' ') {
					this.controller.revealAround();
				}
				return;
			}

			if (ev.getModifierState("Shift")) {
				this.controller.flagToward(direction.x, direction.y);
			} else {
				this.controller.walkToward(direction.x, direction.y);
			}
		});
	},
	computed: {
		blownUp() {
			return this.controller.blownUp;
		},
		whatsHere() {
			this.whatsHereBackdoor;
			const global = { x: this.controller.player.x, y: this.controller.player.y };
			const chunkLoc = this.controller.myChunkLoc;
			const hereChunk = this.controller.chunks.get(`${chunkLoc.u},${chunkLoc.v}`);
			const local = extras.globalLocToLocal(global.x, global.y, chunkLoc.u, chunkLoc.v);
			const ref = new SpaceRef(global, hereChunk, local);
			return ref.displayChar;
		},
		showAdminControls() {
			return this.controller.player.role == "admin";
		}
	},
	methods: {
		
	}
}
</script>