<template>
  <div class="home">
    <h1>Minesweeper.io</h1>
    <div v-if="player">
      <h2>Welcome back, {{player.name}}</h2>
      <p>
        <button class="button" @click="play">Play</button>
        <router-link to="/register" class="button" v-if="!player.username">Register</router-link>
        <button class="button" @click="logout">Log Out</button>
      </p>
    </div>
    <div v-else-if="loading">
      <h2>Loading</h2>
    </div>
    <div v-else>
      <h2>Enter your name</h2>
      <input placeholder="Example: headhunter114" v-model="name">
      <br>
      <p><button class="button" @click="makePlayerAndPlay">Play</button></p>
      <p>or</p>
      <p><router-link to="/login" class="button">Login</router-link></p>
    </div>
  </div>
</template>

<style scoped>


</style>

<script>
import axios from 'axios';
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      name: '',
      loading: false,
    };
  },
  computed: {
    player() {
      return this.$root.$data.player;
    }
  },
  methods: {
    async makePlayerAndPlay() {
      try {
        const response = await axios.post('/api/player', { name: this.name });
        this.$root.$data.player = response.data.player;
        this.play();
      } catch (err) {
        console.error(err);
      }
    },
    play() {
      this.$router.push({ name: 'Play' });
    },
    async logout() {
      this.loading = true;
      try {
        const response = await axios.delete('/api/player');
        if (response) {
          console.log(response);
          this.$root.$data.player = undefined;
        }
      } catch (err) {
        console.error(err);
        console.log("Couldn't delete the current player.");
      }
      this.loading = false;
    }
  }
}
</script>