<template>
<div class="hero">
  <div class="heroBox" v-if="!player">
    <form class="pure-form">
      <fieldset>
        <legend>Login</legend>
        <input placeholder="username" v-model="usernameLogin">
        <input type="password" placeholder="password" v-model="passwordLogin"><br>
        <button type="submit" class="pure-button pure-button-primary" @click.prevent="login">Login</button>
      </fieldset>
    </form>
    <p v-if="errorLogin" class="error">{{errorLogin}}</p>
    <router-link to="/">Back to Home</router-link>
  </div>
  <div v-else>
    <h2>You must log out first.</h2>
    <router-link to="/">Back to Home</router-link>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      usernameLogin: '',
      passwordLogin: '',
      errorLogin: '',
    }
  },
  computed: {
    player() {
      return this.$root.$data.player;
    }
  },
  methods: {
    async login() {
      this.errorLogin = '';
      if (!this.usernameLogin || !this.passwordLogin)
        return;
      try {
        let response = await axios.post('/api/player/login', {
          username: this.usernameLogin,
          password: this.passwordLogin,
        });
        this.$root.$data.player = response.data.player;
        this.$router.push({path: '/'});
      } catch (error) {
        this.errorLogin = "Error: " + error.response.data.message;
        this.$root.$data.player = null;
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-variant: capitalize;
}

.hero {
  padding: 30px;
  display: flex;
  justify-content: center;
}

.heroBox {
  background: white;
  opacity: 0.95;
  display: inline;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  border-radius: 30px;
}

.hero::after {
  content: "";
  background-image: url("/pagliacci.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.hero img {
  height: 20px;
  margin: 0px;
}

.hero form {
  font-size: 14px;
}

.hero form legend {
  font-size: 20px;
}

input {
  margin-right: 10px;
}

.error {
  margin-top: 20px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}
</style>
