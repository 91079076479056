<template>
<div class="field">
	<div id="field-box">
		<!--<p v-for="(line,index) in lines" :key="index">{{line}}</p>-->
		<div v-for="v in vrange" class="debugRow" :key="v">
			<div v-for="u in urange" :key="u" class="chunkDebug">
				<p :key="needsRender">{{renderedChunkAtUV(u,v)}}</p>
			</div>
		</div>
	</div>
</div>
</template>

<style scoped>
p {
	--game-text-scale: 36px;
}

p {
	margin: 0;
	font-size: var(--game-text-scale);
	font-family: 'Courier New', Courier, monospace;
}
.field {
	flex-grow: 1;
	position: relative;
}
#field-box {
	position: absolute;
	left: -298px;
	right: -298px;
	bottom: -300px;
	top: -300px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	z-index: -10;
}
.debugRow {
	display: flex;
	flex-direction: row;
}
.chunkDebug {
	--game-chunk-width: 298px;
	--game-chunk-height: 300px;
	width: var(--game-chunk-width);
	height: var(--game-chunk-height);
}

@media only screen and (max-width: 600px) {
	p {
		--game-text-scale: 24px;
	}
	.chunkDebug {
		--game-chunk-width: 199px;
		--game-chunk-height: 200px;
	}
}

@media only screen and (max-width: 400px) {
	p {
		--game-text-scale: 18px;
	}
	.chunkDebug {
		--game-chunk-width: 149px;
		--game-chunk-height: 150px;
	}
}
</style>

<script>
import { extras } from '../../public/extras';
export default {
	name: 'MapViewer',
	props: {
		controller: Object
	},
	data() {
		return {
			verticalVision: 0,
			horizontalVision: 0,
			renderedChunks: new Map(),
			chunksNeedingRender: new Set(), // Holds stringified uv points.
			needsRender: true,
		}
	},
	computed: {
		urange() {
			const myULoc = this.controller.myChunkLoc.u;
			// Range from myLoc.u - horizVis ... myLoc.u + horizVis, inclusive.
			return [...Array(this.horizontalVision*2+1).keys()].map(i => i + myULoc - this.horizontalVision);
		},
		vrange() {
			const myVLoc = this.controller.myChunkLoc.v;
			// Range from myLoc.v - vertVis ... myLoc.v + vertVis, inclusive.
			return [...Array(this.verticalVision*2+1).keys()].map(i => i + myVLoc - this.verticalVision);
		},
		visibleBounds() {
			return {
				umin: this.controller.myChunkLoc.u - this.horizontalVision,
				umax: this.controller.myChunkLoc.u + this.horizontalVision,
				vmin: this.controller.myChunkLoc.v - this.verticalVision,
				vmax: this.controller.myChunkLoc.v + this.verticalVision,
			}
		}
	},
	watch: {
	},
	mounted() {
		this.controller.renderer = this;
		console.log("mounted renderer");

		this.resizeVisibility();
		window.onresize = () => {
			this.resizeVisibility();
		};
		this.render();
	},
	methods: {
		renderedChunkAtUV(u, v) {
			if (this.needsRender) {
				this.needsRender = false;
				this.render();
			}
			const key = `${u},${v}`;
			if (this.chunksNeedingRender.has(key) || !this.renderedChunks.has(key)) {
				return this.renderAtUV(u, v);
			} else {
				return this.renderedChunks.get(key);
			}
		},
		setChunkNeedsRender(u, v) {
			this.chunksNeedingRender.add(`${u},${v}`);
			// FIXIT Make Vue understand when certain chunks need to be rerendered.
			// Right now I'm just making it rerender the screen all the time.
			this.needsRender = true;
		},
		render() {
			this.renderedChunks.clear();
			this.chunksNeedingRender.clear();
			for (const v of this.vrange) {
				for (const u of this.urange) {
					this.renderAtUV(u, v);
				}
			}
		},
		renderAtUV(u, v) {
			const chunkSize = extras.chunkSize;
			const players = this.controller.players;
			const chunk = this.controller.chunks.get(`${u},${v}`);
			// If this chunk doesn't exist, just draw a blank.
			if (!chunk) {
				const result = "";//extras.invalidDisplay.repeat(chunkSize*chunkSize);
				this.renderedChunks.set(`${u},${v}`, result);
				return result;
			}
			let result = "";
			for (let locy = 0; locy < chunkSize; locy++) {
				for (let locx = 0; locx < chunkSize; locx++) {
					const here = extras.refToLocal(chunk, locx, locy);
					// In most cases, we can use displayChar to display this space.
					let hereChar = here.displayChar;
					// Check if there's a player here.
					const globalLoc = here.global;
					for (const player of players.values()) {
						if (globalLoc.x === player.x && globalLoc.y === player.y) {
							// A player is here.
							hereChar = player.symbol;
							break;
						}
					}
					result = result.concat(hereChar);
				}
				result = result.concat(' ');
			}
			const key = `${u},${v}`;
			this.renderedChunks.set(key, result);
			this.chunksNeedingRender.delete(key);
			return result;
		},
		resizeVisibility() {
			const fieldBox = document.getElementById('field-box');
			const width = fieldBox.clientWidth;
			const height = fieldBox.clientHeight;
			// Just fit what we can inside the field box.
			const chunkElement = document.getElementsByClassName('chunkDebug')[0];
			const chunkWidth = parseInt(getComputedStyle(chunkElement).getPropertyValue('--game-chunk-width')) || 298.0;
			const chunkHeight = parseInt(getComputedStyle(chunkElement).getPropertyValue('--game-chunk-height')) || 300.0;
			const chunksTall = Math.floor(height / chunkHeight);
			const chunksWide = Math.floor(width / chunkWidth);
			this.verticalVision = Math.floor((chunksTall - 1) / 2);
			this.horizontalVision = Math.floor((chunksWide - 1) / 2);
			console.log(`visibility=${this.horizontalVision}x${this.verticalVision}`);
		},
	}
}
</script>