<template>
<div>
	<div class="overlay" v-if="showingModal">
		<div class="center">
			<Respawn v-if="blownUp" :controller="this"/>
			<Help v-if="showHelp" :controller="this"/>
			<Scoreboard v-if="showScoreboard" :controller="this"/>
			<DungeonEntered v-if="showDungeonDialog" :controller="this"/>
			<DungeonCompleted v-if="showDungeonCompletedDialog" :controller="this"/>
			<button @click="dismissDialogs" v-if="!blownUp">Close</button>
		</div>
	</div>
	<div class="play" v-if="loaded">
		<MapViewer :controller="this" />
		<HUD :controller="this" />
	</div>
	<div v-else>
		<h1>Loading</h1>
		<router-link to="/">Back to Home</router-link>
	</div>
</div>
</template>

<style scoped>

.play {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.overlay {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.8);
	z-index: 10000;
	display: flex;
	justify-content: center;
	color: white;
}
.center {
	justify-self: center;
	align-self: center;
}

</style>

<script>
import HUD from '../components/HUD.vue';
import MapViewer from '../components/MapViewer.vue'
import { extras } from '../../public/extras'
import Respawn from '../components/Respawn.vue'
import Help from '../components/Help.vue'
import Scoreboard from '../components/Scoreboard.vue'
import DungeonEntered from '../components/DungeonEntered.vue'
import DungeonCompleted from '../components/DungeonCompleted.vue'
export default {
	name: "Play",
	components: {
		HUD,
		MapViewer,
		Respawn,
		Help,
		Scoreboard,
		DungeonEntered,
		DungeonCompleted,
	},
	data() {
		return {
			blownUp: false,
			loaded: false,
			connection: undefined,
			chunks: new Map(),
			players: new Map(), // Keyed by name
			renderer: undefined,
			hud: undefined,
			showHelp: false,
			showScoreboard: false,
			showDungeonDialog: false,
			showDungeonCompletedDialog: false,
		};
	},
	computed: {
		player() {
			return this.$root.$data.player;
		},
		myChunkLoc() {
			if (!this.loaded) {
				return { u: 0, v: 0 };
			}
			return extras.chunkLocAt(this.player.x, this.player.y);
		},
		loadedBounds() {
			if (!this.loaded) {
				return { umin: 0, umax: 0, vmin: 0, vmax: 0 };
			}
			if (!this.renderer) {
				return {
					umin: this.myChunkLoc.u - 1,
					umax: this.myChunkLoc.u + 1,
					vmin: this.myChunkLoc.v - 1,
					vmax: this.myChunkLoc.v + 1,
			};

			}
			return {
				umin: this.myChunkLoc.u - Math.max(1, this.renderer.horizontalVision),
				umax: this.myChunkLoc.u + Math.max(1, this.renderer.horizontalVision),
				vmin: this.myChunkLoc.v - Math.max(1, this.renderer.verticalVision),
				vmax: this.myChunkLoc.v + Math.max(1, this.renderer.verticalVision),
			};
		},
		showingModal() {
			return this.blownUp || this.showHelp || this.showScoreboard || this.showDungeonDialog || this.showDungeonCompletedDialog;
		}
	},
	watch: {
		loadedBounds(newValue, oldValue) {
			if (!this.loaded) {
				return;
			}
			if (newValue.umin === oldValue.umin &&
				newValue.umax === oldValue.umax &&
				newValue.vmin === oldValue.vmin &&
				newValue.vmax === oldValue.vmax) {
				return;
			}
			// FIXIT This is never called.
			this.send("visible bounds", newValue);
		},
		player() {
			this.checkIsLoaded();
		},
		renderer(newValue, oldValue) {
			if (newValue === oldValue || newValue === undefined) {
				return;
			}
			for (const v of this.renderer.vrange) {
				for (const u of this.renderer.urange) {
					this.renderer.setChunkNeedsRender(u, v);
				}
			}
		}
	},
	mounted() {
		const ws = new WebSocket('wss://minehunt.bbhintze.com/api/game');
		const play = this;
		ws.onopen = function(e) {
			console.log(`WebSocket Client Connected: ${e}`);
			play.connection = ws;
			play.players.set(play.player.name, play.player);
			play.checkIsLoaded();
			// Tell the server what our visible bounds are.
			play.send("visible bounds", play.loadedBounds);
		}
		ws.onmessage = function(e) {
			//console.log(`Received: ${e.data}`);
			try {
				const message = JSON.parse(e.data);
				const type = message.type;
				const data = message.data;
				if (type === undefined || data === undefined) {
					throw new ErrorEvent("message missing type or data");
				}
				play.receive(type, data);
			} catch (err) {
				console.error(`Couldn't process message: ${err}`);
			}
		}
		ws.onclose = function(e) {
			console.log(`DISCONNECTED: code=${e.code}, reason=${e.reason}`);
		};
	},
	beforeDestroy() {
		this.connection?.close(1000, "leaving play");
	},
	methods: {
		checkIsLoaded() {
			if (!this.connection || !this.player || this.chunks.size === 0) {
				this.loaded = false;
			} else {
				this.loaded = true;
			}
		},
		dismissDialogs() {
			this.showHelp = false;
			this.showScoreboard = false;
			this.showDungeonDialog = false;
			this.showDungeonCompletedDialog = false;
		},
		walkToward(dx, dy) {
			this.send("move", { dx, dy });
		},
		flagToward(dx, dy) {
			this.send("flag", { dx, dy });
		},
		revealAround() {
			this.send("explore around", {});
		},
		respawn() {
			this.send("respawn", {});
			this.blownUp = false;
		},
		send(type, data) {
			try {
				const messageObject = { type, data };
				const message = JSON.stringify(messageObject);
				if (type === undefined || data === undefined) {
					throw new ErrorEvent("message to send missing type or data");
				}
				//console.log(`Sending message: ${message}`);
				this.connection.send(message);
			} catch (err) {
				console.error(err);
			}
		},
		receive(type, data) {
			if (type === "chunks") {
				for (const c of data.chunks) {
					const key = `${c.u},${c.v}`;
					this.chunks.set(key, c);
					if (this.renderer) {
						this.renderer.setChunkNeedsRender(key);
					}
					if (this.hud) {
						if (this.myChunkLoc.u === c.u && this.myChunkLoc.v === c.v) {
							this.hud.whatsHereBackdoor++;
						}
					}
				}
				if (!this.loaded) {
					this.checkIsLoaded();
				}
			} else if (type === "player") {
				this.receivePlayer(data.player);
			} else if (type === "players") {
				for (const p of data.players) {
					this.receivePlayer(p);
				}
			} else if (type === "blew up") {
				this.blownUp = true;
			} else if (type === "spawned") { // NOTE am I using this?
				this.blownUp = false;
			} else if (type === "staged dungeon room") {
				if (data.roomIndex === 0) {
					// Dungeon found!
					this.showDungeonDialog = true;
				} else if (data.scoreReceived === extras.dungeonReward) {
					// Treasure room!
					this.showDungeonCompletedDialog = true;
				} else {
					// Puzzle was beat, nothing more.
					console.log('Beat a level.')
				}
			} else {
				throw new Error("What do I do with this? " + type);
			}
		},
		receivePlayer(player) {
			if (this.players.has(player.name)) {
				const thisPlayerHere = this.players.get(player.name);
				const oldUV = extras.chunkLocAt(thisPlayerHere.x, thisPlayerHere.y);
				// Make sure that we rerender the place this player was.
				this.renderer.setChunkNeedsRender(oldUV.u, oldUV.v);
				//Object.assign(thisPlayerHere, player);
				const keys = ['x', 'y', 'score', 'inPlay', 'startPosition', 'symbol', 'discoveryBonusAchieved'];
				for (const k of keys) {
					thisPlayerHere[k] = player[k];
				}

				if (this.player === thisPlayerHere) {
					// FIXIT. We shouldn't need to send the visible range at this player's update.
					this.send("visible range", this.loadedBounds);
				}
			} else {
				this.players.set(player.name, player);
			}

			const newUV = extras.chunkLocAt(player.x, player.y);

			// Make sure that we rerender the place this player now is.
			this.renderer.setChunkNeedsRender(newUV.u, newUV.v);
		},
		generateDungeon() {
			this.send("generate dungeon here", { x: this.player.x, y: this.player.y, direction: 2, numPuzzleRooms: 5 });
		},
		clearAll() {
			this.send("clear entire map", {});

		}
	}
}
</script>
