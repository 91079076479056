<template>
<div>
  <div class="hero">
    <div class="heroBox" v-if="player && player.role==='guest'">
      <form class="pure-form">
        <fieldset>
          <legend>Register for an account</legend>
          <p>Username: {{player.name}}</p>
          <input type="password" placeholder="password" v-model="password">
          <br>
          <button type="submit" class="pure-button pure-button-primary" @click.prevent="register">Register</button>
        </fieldset>
      </form>
      <p v-if="error" class="error">{{error}}</p>
    </div>
    <div v-else>
      <h1>You must be logged in as a guest to register.</h1>
    </div>
  </div>
  <br>
  <router-link class="link" to="/">Back to Home</router-link>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      password: '',
      error: '',
      errorLogin: '',
    }
  },
  computed: {
    player() {
       return this.$root.$data.player;
    }
  },
  methods: {
    async register() {
      this.error = '';
      this.errorLogin = '';
      if (!this.password)
        return;
      try {
        let response = await axios.post('/api/player/register', {
          username: this.player.name,
          password: this.password,
        });
		this.$root.$data.player = response.data.player;
		this.$router.push({ path: '/' });
      } catch (error) {
        this.error = error.response.data.message;
        this.$root.$data.player = undefined;
      }
    },
  }
}
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-variant: capitalize;
}

.hero {
  padding: 30px;
  display: flex;
  justify-content: center;
}

.heroBox {
  background: white;
  opacity: 0.95;
  display: inline;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  border-radius: 30px;
}

.hero::after {
  content: "";
  background-image: url("/pagliacci.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.hero img {
  height: 20px;
  margin: 0px;
}

.hero form {
  font-size: 14px;
}

.hero form legend {
  font-size: 20px;
}

input {
  margin-right: 10px;
}

.error {
  margin-top: 20px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}
</style>
