<template>
<div>
	<h1>You blew up</h1>
	<h2>Your score: {{player.score}}</h2>
	<p><button @click="respawnPressed">Respawn</button></p>
	<Scoreboard />
</div>
</template>

<script>
import axios from 'axios';
import Scoreboard from '@/components/Scoreboard.vue';

export default {
	name: "Respawn",
	components: {
		Scoreboard
	},
	props: {
		controller: Object,
	},
	data() {
		return {
			name: ""
		}
	},
	mounted() {
		axios.post('/api/scoreboard', { score: this.player.score, name: this.player.name } );
	},
	methods: {
		respawnPressed() {
			this.controller.respawn();
		},
	},
	computed: {
		player() {
			return this.controller.player;
		},
	}
}
</script>