

<template>
<div class="page">
	<h2>Dungeon completed</h2>
	<h3>250 points awarded</h3>
	<p>You have proven yourself worthy of this treasure. Go now and use it well.</p>
</div>
</template>

<script>
export default {
	name: "DungeonCompleted",
	data() {
		return {
		}
	}
}
</script>

<style scoped>
</style>