<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'app',
  async mounted() {
    try {
      const response = await axios.get('/api/player');
      if (response) {
        this.$root.$data.player = response.data.player;
      }
    } catch (err) {
      console.error(err);
      console.log("Couldn't get the current player.  Preparing to make a new one...");
    }
  },
}
</script>

<style>
* {
  box-sizing: border-box;
}

/* green yellow red orange */
/* 96ceb4 ffeead d9534f ffad60 */
body {
  margin: 0px;
  background: #05051f;
  color: #ccdede
}

input {
  padding: 5px;
}

.button {
  display: inline;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  margin-left: 10px;
  margin-right: 10px;
}


#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.main {
  padding: 20px 100px;
}

.pure-button {
  font-size: 10px;
}

.space-right {
  margin-right: 10px;
}

a {
  color: #ffad60;
}

.link {
  color: #ffad60;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 30px;
}

.pure-button-primary {
  background-color: #ffad60;
  color: #333;
  border-radius: 30px;
}
</style>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
