
<template>
<div class="page">
	<h2>Welcome to the dungeon</h2>
	<p>You have entered a dungeon, traveler. Enter if you dare. At its heart, you will find a great reward. But only the wisest may unravel the secrets of the puzzle rooms of the dungeon.</p>
	<p>Your goal is to cross each room without blowing up. Tread lightly, for there are bombs on every side, and in the smoke, you cannot count nearby bombs the way you normally do. You must deduce what is the safest path based only on the clues given. Your only hope is to rely on the flags and number tiles given by the ancients. But <em>there is always a path across.</em></p>
	<p>You may flag tiles, but the smoke keeps your engineers from reaching them to disarm the bombs. Flags will take anywhere in the smoke, and flagging a tile doesn't confirm that it was a bomb, and you can unflag any flagged tile. Flagging a tile will also help you avoid stepping on it. In a dungeon, flags are used mainly to help you identify the path across a puzzle.</p>
	<p>Reaching the treasure room of this lesser dungeon will give you 250 points. There are greater dungeons elsewhere that will bring greater rewards.</p>
</div>
</template>

<script>
export default {
	name: "DungeonEntered",
	data() {
		return {
		}
	}
}
</script>

<style scoped>
</style>